import React from 'react';
import './navbar.css';
import Logo from './Logo/Logo';
import MainMenu from './MainMenu/MainMenu';

export default function Navbar() {
    return (
        <div className="navbar">
            <Logo />
            <MainMenu />
        </div>
    );
}
