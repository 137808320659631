import React from 'react';
import './logo.css';
import { links } from '../../../data/data.json';

export default function Logo() {
    const { sekretUrl } = links;
    const logo = `${sekretUrl}/sekretlogo.png`;
    return (
        <div className="logo-name-container">
            <a className="home-logo-link" href="#">
                <img className="logo-img" src={logo} alt="Logo" />
                <div className="logo-text">
                    SEKRET
                </div>
            </a>

        </div>
    );
}
