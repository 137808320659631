import React from 'react';
import './trustedBy.css';
import { main } from '../../data/data.json';
import Partners from './Partners/Partners';

export default function Header() {
    const { trustedTitle, trustedSubtitle } = main;
    return (
        <div className="trusted-by">
            <div className="headliner-text">
                {trustedTitle}
            </div>
            <br />
            <div className="headliner-subtext">
                {trustedSubtitle}
            </div>
            <br />
            <Partners />
        </div>
    );
}
