import React from 'react';
import './seekrLink.css';
import { links, main } from '../../../../data/data.json';

export default function SeekrLink() {
    const { app } = links;
    const { appBtn } = main;
    return (
        <div className="seekr-app-link">
            <a className="seekr-link-text" href={app} target="_blank" rel="noreferrer">{appBtn}</a>
        </div>
    );
}
