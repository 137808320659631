import React from 'react';
import './app.css';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import TrustedBy from './components/TrustedBy/TrustedBy';
import WhatIsIt from './components/WhatIsIt/WhatIsIt';
import FindUsOn from './components/FindUsOn/FindUsOn';
import Footer from './components/Footer/Footer';

document.body.style = 'background:#131319;';

export default function App() {
    return (
        <div className="app">
            <Navbar />
            <Header />
            <TrustedBy />
            <WhatIsIt />
            <FindUsOn />
            <Footer />
        </div>
    );
}
