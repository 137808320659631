import React from 'react';
import './menuButton.css';
import { Button } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

export default function MainMenu(props) {
    const { handleClick, open } = props;
    return (
        <Button
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <MenuIcon
                sx={{
                    fontSize: '38px',
                    color: '#FFFDA4',
                }}
            />
        </Button>
    );
}
