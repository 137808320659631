import React from 'react';
import './findUsOn.css';
import { main } from '../../data/data.json';
import Platforms from './Platforms/Platforms';

export default function WhatIsIt() {
    const { findUsOnTitle } = main;
    return (
        <div className="find-us-on">
            <div className="headliner-text">
                {findUsOnTitle}
            </div>
            <br />
            <Platforms />
        </div>
    );
}
