import React from 'react';
import './whatIsIt.css';
import AppIntro from './AppIntro/AppIntro';
import { main } from '../../data/data.json';

export default function WhatIsIt() {
    const { whatIsItTitle, whatIsItSubtext, whatIsItSubtext2 } = main;
    return (
        <div className="what-is-it">
            <div className="headliner-text">
                {whatIsItTitle}
            </div>
            <br />
            <div className="headliner-subtext">
                {whatIsItSubtext}
            </div>
            <br />
            <div className="headliner-subtext">
                {whatIsItSubtext2}
            </div>
            <br />
            <AppIntro />
        </div>
    );
}
