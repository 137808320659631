import React from 'react';
import './partners.css';
import { links } from '../../../data/data.json';
import { twitter } from './partnerLinks.json';

export default function Header() {
    const { baseImgUrl } = links;
    return (
        <div className="partners">
            {
                Object.keys(twitter).map(key => (
                    <a href={twitter[key]} target="_blank" rel="noreferrer" key={key}>
                        <img
                            src={`${baseImgUrl}/partners/${key}gray.png`}
                            alt="logo"
                            className="partner-logo"
                        />
                    </a>
                ))
            }
        </div>
    );
}
