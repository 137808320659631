import React from 'react';
import './mainMenu.css';
import {
    Menu,
    MenuItem,
    Fade
} from '@mui/material';
import {
    Telegram,
    Twitter,
    Handshake
} from '@mui/icons-material';
import MenuButton from './MenuButton/MenuButton';
import SeekrLink from './SeekrLink/SeekrLink';
import { links } from '../../../data/data.json';

export default function MainMenu() {
    const { telegram, twitter, gform } = links;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const menuBtnProps = {
        handleClick,
        open
    };
    return (
        <div className="menu-container">
            <MenuButton {...menuBtnProps} />
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
            >
                <MenuItem onClick={handleClose}>
                    <nav>
                        <SeekrLink />
                    </nav>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Telegram style={{ color: 'white' }} />
                    <a className="nav-item" href={telegram} target="_blank" rel="noreferrer">
                        Telegram
                    </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Twitter style={{ color: 'white' }} />
                    <a className="nav-item" href={twitter} target="_blank" rel="noreferrer">
                        Twitter
                    </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Handshake style={{ color: 'white' }} />
                    <a className="nav-item" href={gform} target="_blank" rel="noreferrer">
                        Work with us
                    </a>
                </MenuItem>
            </Menu>
        </div>
    );
}
